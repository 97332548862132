import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const FeestPage = () => (
  <Layout>
    <SEO title="Feest" />
    <p>Beste Partypeople,</p>
    <p>Het IVe lustrum der HPH Claymore zal op 16 januari knallend van start gaan! Met het openingsfeest Tofflords zal de spits afgebeten worden van wat belooft een mooi lustrumjaar te worden. </p>
    <p>Dans je dansjes op o.a. HAPPY FEELINGS, Feij x BolderBeats en Barros Soundsystem, en leef je uit op ons onbeperkte drankassortiment: Bier, wijn, fris, malibu, (rode)vodka, jagermeister, bacardi (razz, lemon, oakhart), whisky, tequila, sambucca, southern comfort, gin.</p>
    <p>Al met al belooft het een legendarische avond te worden, dus haal snel je kaartje en sleep je club, dispuut, huis en vvv'tjes mee naar dit niet te missen fuifje!</p>
    <p>Dus:<br />
      16/01/2020 <br />
      Toffler // 22:00-04:00 <br />
      Man // 30 eu<br />
      Vrouw // 22,50 eu</p>
    <p>
      Kaartjes zijn te koop via de volgende manieren:<br />
      - In de bar van Koinoonia<br />
      - via TIKKIE op aanvraag (stuur 1 van ons een berichtje) <br />
      - of direct via <a href="https://www.eventbrite.nl/e/tickets-claymore-presents-tofflords-83020333051" target="_blank">Eventbrite</a> (maar met servicekosten)<br />

    </p>
  </Layout>
)

export default FeestPage
